'use client';
import React from 'react';
import me from '../Assets/img/me.png';
import '../Style/Morgane.css';
import { Skills } from './Skills';

function Morgane() {
  async function copy() {
    try {
      await navigator.clipboard.writeText('morgane.picciotto@gmail.com');
      alert('Email copier dans le press-papiers !');
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <section className="presentation">
      <div className="photo-name">
        <img src={me} className="maphoto" alt="Morgane Picciotto" />
        <h2 className="name">Morgane Picciotto</h2>
      </div>
      <div className="presentation-text-box">
        <p className="presentation-text">
          {/* Je suis développeuse Web diplômée par Openclassroom, spécialisée en
          React et Javascript / Typescript, je suis professionnelle, investie et
          rigoureuse dans mes projets. En dehors du travail, mes proches me
          décrivent comme une personne sociable, ouverte d'esprit et passionnée
          par les sciences et les jeux vidéo. Je suis animée par la volonté de
          bien faire, tant sur le plan professionnel que personnel, et je suis
          toujours prête à relever de nouveaux défis."Oui j'aime faire des
          animations" */}
          Développeuse web freelance diplômée par OpenClassrooms. Spécialisée en
          React/Next, JavaScript / TypeScript, Node.js et SEO. Créatrice de Site
          complexe, interactif et optimisé. Passionnée par la Tech et les
          Sciences, on me dit sociable et ouverte d'esprit.
        </p>
      </div>
      <div className="presentation-button-box">
        <button
          onClick={copy}
          className="presentation-button presentation-email"
          type="button"
        >
          <strong>Copier mon Email</strong>
        </button>
        <a
          href="https://www.linkedin.com/in/morgane-picciotto-developpeur-web/"
          target="blank"
          className="link-linkedin"
          rel="noopener noreferrer"
        >
          <button
            className="presentation-button presentation-linkedin"
            type="button"
          >
            <strong>Mon Linkedin</strong>
          </button>
        </a>
      </div>
      <Skills></Skills>
    </section>
  );
}

export default Morgane;

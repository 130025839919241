import React from 'react';
import '../Style/Header.css';

export default function Header() {
  return (
    <header>
      <h1>&#128187; Développeur Web Freelance</h1>
      <nav className="link">
        <a
          href="https://www.linkedin.com/in/morgane-picciotto-developpeur-web/"
          target="blank"
          rel="morgane picciotto linkedin"
        >
          <span className="linkedin"></span>
        </a>
        <a
          href="https://github.com/MorganeAzure"
          target="blank"
          rel="morgane picciotto github"
        >
          <span className="github"></span>
        </a>
      </nav>
    </header>
  );
}
